import { Router, NavigationStart } from "@angular/router";
import { AlertsService } from "./../../_services/alerts.service";
import { AuthenticationService } from "./../../_services/authentication.service";
import { NotificationService } from "./../../_services/notification.service";
import { Component, OnInit, Input } from "@angular/core";
import { PspUser } from "./../../_models/user.model";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  videoUrlen;
  videoUrlde;
  userSubscription: Subscription;
  currentUser: PspUser;
  videoError = false;
  videoLoadingEn = true;
  videoLoadingDe = true;
  name: string;
  favSite;
  refreshSubscription: Subscription;
  browserRefresh: boolean;
  constructor(
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private alertsService: AlertsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.refreshSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.router.navigated) {
          console.log(`Browser refresh home: ${this.browserRefresh}`);
          this.getUser();
        }
      }
    });
    // const routerEvents = this.router.events;
    // routerEvents
    //   .filter(
    //     event => event instanceof NavigationStart && !this.router.navigated
    //   )
    //   .switchMap(() => this.authenticationService.getUser())
    //   .subscribe(user => {
    //     this.authenticationService.currentUserSubject.next(user);
    //     console.log(`from refresh`);
    //   });

    this.notificationService.getVideo("ACMOverview720en.mp4").subscribe(
      data => {
        console.log("en landing video got");
        console.log(data);
        this.videoUrlen = data;
        this.videoLoadingEn = false;
      },
      error => {
        this.videoError = true;
        this.videoLoadingEn = false;
      }
    );

    this.favSite = this.alertsService.getFavouriteAlertsSite(
      this.authenticationService.loggedInUserHashId()
    );

    this.userSubscription = this.authenticationService.currentUserSubject.subscribe(
      User => (this.name = User.name)
    );
  }

  getUser() {
    this.authenticationService
      .getUser()
      .subscribe(user =>
        this.authenticationService.currentUserSubject.next(user)
      );
  }
  canAccessAlerts() {
    return this.authenticationService.hasRole("alert");
  }
  canAccessDashboards() {
    return this.authenticationService.hasRole("dash");
  }
  canAccessFleet() {
    return this.authenticationService.hasRole("fleet");
  }
  canAccessMsr() {
    return this.authenticationService.hasRole("msr");
  }

  goToFavSite() {
    this.router.navigate(["alerts", this.favSite.siteId]);
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe;
  }
}
