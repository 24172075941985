import { ConnStrModule } from "./../_services/conn-str.module";
import { AuthenticationService } from "./../_services/authentication.service";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/finally";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/take";

//https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private connStrModule: ConnStrModule
  ) {}

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: "Bearer " + token
      }
    });
  }
  addAuthenticationToken(req) {
    // Get access token from Local Storage
    const accessToken = this.authenticationService.getToken();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return req;
    }

    // We clone the request, because the original request is immutable
    return req.clone({
      setHeaders: {
        Authorization: "Bearer " + this.authenticationService.getToken()
      }
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //req = this.addToken(req, this.authenticationService.getToken());
    if (req.url.toLowerCase().indexOf(this.connStrModule.pspBaseUrl) >= 0) {
      console.log(req.url.toString());
    } else {
      return next.handle(req);
    }
    return <any>(
      next
        .handle(this.addToken(req, this.authenticationService.getToken()))
        .catch(error => {
          if (req.url.includes("refreshtoken") || req.url.includes("token")) {
            //we dont want to intercept refreshes or token request
            console.log("hub is not intercepting login");
            return next.handle(req);
          }

          // If error status is different than 401 we want to skip refresh token
          // So we check that and throw the error if it's the case
          if (error.status !== 401) {
            console.log(`error.status: ${error.status}`);

            return Observable.throw(error);
          }

          if (this.isRefreshingToken) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // – which means the new token is ready and we can retry the request again
            return this.tokenSubject
              .filter(result => result !== null)
              .take(1)
              .switchMap(() => next.handle(this.addAuthenticationToken(req)));
          } else {
            this.isRefreshingToken = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.tokenSubject.next(null);

            // Call auth.refreshAccessToken(this is an Observable that will be returned)
            return this.authenticationService
              .refreshHubToken()
              .switchMap((data: any) => {
                this.authenticationService.processHubTokens(data);
                let newToken = this.authenticationService.getToken();
                if (newToken) {
                  this.tokenSubject.next(newToken);
                  req = this.addToken(req, newToken);
                  this.isRefreshingToken = false;
                  return next.handle(req);
                }
                //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                // for the next time the token needs to be refreshed
                /* this.isRefreshingToken= false;
                      this.tokenSubject.next(token);

                      return next.handle(this.addAuthenticationToken(req));*/
              })
              .catch((err: any) => {
                this.isRefreshingToken = false;

                this.authenticationService.logout();
                return Observable.throw(error);
              });
          }
        })
    );
  }

  /**           if (error instanceof HttpErrorResponse) {
                switch (( < HttpErrorResponse > error).status) {
                case 400:
                    return this.handle400Error(error);
                case 401:
                    return this.handle401Error(req, next);
                }
            } else {
                return Observable.throw(error);
            }
        });
    }



    
  handle400Error(error) {
    console.log("400 error");
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logoutUser();
    }

    return Observable.throw(error);
  }


  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    console.log("401 error");
    if (!this.isRefreshingToken) {
        this.isRefreshingToken = true;
        console.log("isRefreshingToken", this.isRefreshingToken);

        // Reset here so that the following requests wait until the token
        // comes back from the refreshToken call.
        this.tokenSubject.next(null);

        return this.authenticationService.refreshHubToken()
            .switchMap((data) => {
                this.authenticationService.processHubTokens(data);
                let newToken = this.authenticationService.getToken();
                if (newToken) {
                    this.tokenSubject.next(newToken);
                    req = this.addToken(req, newToken);
                    return next.handle(req);
                }

                // If we don't get a new token, we are in trouble so logout.
                return this.logoutUser();
            })
            .catch(error => {
                // If there is an exception calling 'refreshToken', bad news so logout.
                return this.logoutUser();
            })
            .finally(() => {
                this.isRefreshingToken = false;
            });
    } else {
        console.log("awaiting new token...")
        return this.tokenSubject
            .filter(token => token != null)
            .take(1)
            .switchMap(token => {
              req = this.addToken(req, token);
                return next.handle(req);
            });*/

  logoutUser() {
    this.authenticationService.logout();
    return Observable.throw("");
  }
}
