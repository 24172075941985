import { SignalGetterService } from "./../../_services/signal-getter.service";
import { InfoAlertService } from "./../../_services/info-alert.service";
import { AuthenticationService } from "./../../_services/authentication.service";
import { DataStorageService } from "./../../_services/data-storage.service";
import { FileUploadComponent } from "./../../file-upload/file-upload.component";
import { BlobFile } from "./../../_models/blob-file.model";
import { Subscription } from "rxjs/Subscription";
import { AlertsService } from "./../../_services/alerts.service";
import { Alert } from "./../../_models/alert.model";
import { Site } from "./../../_models/site.model";
import { SitesService } from "../../_services/sites.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { NgbModal, NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-alert-detail",
  templateUrl: "./alert-detail.component.html",
  styleUrls: ["./alert-detail.component.css"]
})
export class AlertDetailComponent implements OnInit {
  alert: Alert = new Alert();
  alertSubscription: Subscription = new Subscription();
  alertFilesSubscription: Subscription = new Subscription();
  tagDataSubscription: Subscription = new Subscription();
  openStatusTypesChangedSubscription: Subscription = new Subscription();
  siteSubscription: Subscription = new Subscription();
  openStatuses;
  requiresBridgeHead: boolean;
  alertId: string;
  siteId: string;
  site: Site;
  isAcm = false;
  isBridgehead = false;
  can_edit = false;
  sendUpdateClicked = false;
  SendEmailclassesObj: String = "btn btn-success my-1 ml-1";

  feedback = "";
  lengthOfAlertFiles: number = 5;

  alertFiles: BlobFile[] = new Array<BlobFile>();
  alertTags = Array<{}>();

  loading = true;
  loadingFiles = true;
  loadingTags = true;
  loadingData = true;
  loadedTags = 0;

  chart;

  chartOptions = {
    title: { text: "" },
    series: [],
    chart: {
      zoomType: "xy",
      reflow: true
      //height: ((9 / 16) * 100) + '%', // 16:9 ratio
      // width: '99%'
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false
        }
      }
    },
    xAxis: {
      crosshair: true,
      type: "datetime"
    },
    credits: {
      enabled: false
    }
  };

  constructor(
    private dataStorageService: DataStorageService,
    private sitesService: SitesService,
    private alertService: AlertsService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private config: NgbCarouselConfig,
    private infoAlertService: InfoAlertService,
    private signalGetterService: SignalGetterService
  ) {}

  ngOnDestroy() {
    this.alertService.clearSeverity();
    this.alertSubscription.unsubscribe();
    this.tagDataSubscription.unsubscribe();
    this.alertFilesSubscription.unsubscribe();
    this.openStatusTypesChangedSubscription.unsubscribe();
    this.siteSubscription.unsubscribe();
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.alertId = params["alertId"];
      this.siteId = params["siteId"];
      // this.alert = this.alertService.getAlert(siteId, alertId);
      this.isAcm = this.authenticationService.isAcm(this.siteId);
      this.isBridgehead = this.authenticationService.isBridgehead(this.siteId);
      console.log(`UserisBridgehead: ${this.isBridgehead}`);
      this.canEditAlert();
      //this.sitesService.getSite(this.siteId);
      this.dataStorageService.getSiteData(this.siteId).subscribe(data => {
        console.log(data);

        this.requiresBridgeHead = data["bridgehead"] == "True";
        this.site = data;
        this.sitesService.siteChanged.next(data);
        this.setSendEmailClass();
        console.log(`Requires Bridgehead: ${this.requiresBridgeHead}`);
      });
    });

    // this.siteSubscription = this.sitesService.siteChanged.subscribe(
    //   (site: Site) => {
    //     this.site = site;
    //     console.log(this.site);

    //     this.requiresBridgeHead = this.site["bridgehead"];
    //     this.setSendEmailClass();
    //   }
    // );
    this.alertSubscription = this.alertService.alertChanged.subscribe(
      (alert: Alert) => {
        console.log(alert);
        this.alert = alert;
        this.loading = false;
        this.getTagsInAlert();
      }
    );

    this.alertFilesSubscription = this.alertService.alertFilesChanged.subscribe(
      (files: BlobFile[]) => {
        this.loadingFiles = false;

        if (files != null) {
          this.alertFiles = files;
        } else {
          files = new Array<BlobFile>();
        }
      }
    );

    this.dataStorageService.fileUploaded.subscribe((uploaded: boolean) => {
      if (uploaded) {
        this.loadingFiles = true;
        this.alertService.getAlertFiles(this.siteId, this.alertId);
      }
    });

    this.openStatusTypesChangedSubscription = this.alertService.openStatusTypesChanged.subscribe(
      data => {
        this.openStatuses = data;
      }
    );
    this.alertService.getAlert(this.siteId, this.alertId);
    this.alertService.getAlertFiles(this.siteId, this.alertId);
    this.alertService.getOpenStatusTypes();
  }
  setSendEmailClass() {
    if (this.requiresBridgeHead) {
      this.SendEmailclassesObj = "btn btn-secondary my-1 ml-1";
    }
  }
  alertHist() {
    if (this.alert.changeLog != null && this.alert.changeLog.length === 0) {
      return this.alert.changeLog.slice();
    }
    let histCopy = this.alert.changeLog.slice();
    let currentSev = null;
    for (let histItem of this.alert.changeLog) {
      if (histItem.fieldChanged === "severity") {
        currentSev = histItem.newContent;
      }
      histItem.currentSeverity = currentSev;
    }
    return histCopy.reverse();
  }

  communicationModifiedBy(comm: string) {
    if (this.alert != null) {
      if (this.alert[comm] != null) {
        if (this.alert[comm]["modifiedBy"] != null) {
          return this.alert[comm]["modifiedBy"]["name"];
        }
      }
    }
    return "";
  }
  canEditAlert() {
    console.log("can edit" + this.isAcm + this.isBridgehead);
    if (this.isAcm || this.isBridgehead) {
      this.can_edit = true;
    }
  }

  communicationModifiedOn(comm: string) {
    if (this.alert != null) {
      if (this.alert[comm] != null) {
        if (this.alert[comm]["utcModified"] != null) {
          return this.alert[comm]["utcModified"];
        }
      }
    }
    return "";
  }

  severity() {
    switch (this.alertSeverity()) {
      case 10: {
        return "severity10";
      }
      case 20: {
        return "severity20";
      }
      case 30: {
        return "severity30";
      }
      case 40: {
        return "severity40";
      }
      case 50: {
        return "severity50";
      }
      case 60: {
        return "severity60";
      }
      default: {
        return "severityNeutral";
      }
    }
  }
  statusClass() {
    if (this.alertIsOngoing()) {
      return "btn-secondary";
    } else {
      return "btn-primary";
    }
  }
  alertImages() {
    const imageArray = [];
    this.alertFiles.forEach(aFile => {
      if (aFile["fileExtension"].indexOf("image") >= 0) {
        imageArray.push(aFile);
      }
    });
    return imageArray;
  }
  alertDocs() {
    const docsArray = [];
    this.alertFiles.forEach(aFile => {
      if (
        aFile["fileExtension"].indexOf("image") < 0 &&
        aFile["fileExtension"].indexOf("video") < 0
      ) {
        docsArray.push(aFile);
      }
    });
    return docsArray;
  }

  alertVideos() {
    const vidArray = [];
    this.alertFiles.forEach(aFile => {
      if (aFile["fileExtension"].indexOf("video") >= 0) {
        vidArray.push(aFile);
      }
    });
    return vidArray;
  }
  openUploader() {
    const modalRef = this.modalService.open(FileUploadComponent);
    modalRef.componentInstance.alertIdIn = this.alertId;
    modalRef.componentInstance.siteIdIn = this.siteId;
    console.log(modalRef.componentInstance);
  }

  unlinkFile(fileHashId: string) {
    if (
      confirm(
        "Are you sure to remove the file " + fileHashId + " from the alert?"
      )
    ) {
      this.alertService.unlinkFile(this.siteId, this.alertId, fileHashId);
    }
  }

  onSubmitFeedback(feedbackForm: NgForm, sendEmailUpdate: boolean) {
    if (this.isBridgehead) {
      sendEmailUpdate = false;
      this.requiresBridgeHead = false;
    }
    console.log("Feedback submitted");
    console.log(this.feedback);
    this.alertService.addFeedback(
      this.siteId,
      this.alertId,
      this.feedback,
      sendEmailUpdate,
      this.requiresBridgeHead
    );

    feedbackForm.reset();
  }

  // fileLoadingText() {
  //   if (this.loadingFiles) {
  //     return true;
  //   } else {
  //     if (this.alertFiles != null) {
  //       this.lengthOfAlertFiles = this.alertFiles.length;
  //       this.filesLoading = false;
  //     }
  //   }
  //   return "Loading files...";
  // }

  chartLoadingText() {
    if (this.loadingTags) {
      return "Searching for tags...";
    } else if (!this.loadingTags && this.alertTags != null) {
      return (
        "Show " +
        this.alertTags.length +
        " live tags (" +
        this.loadedTags +
        "/" +
        this.alertTags.length +
        " loaded)"
      );
    } else {
      return "No tags";
    }
  }
  alertChangeLogCount() {
    if (this.alert != null) {
      if (this.alert.changeLog != null) {
        return this.alert.changeLog.length;
      }
    }
    return "-";
  }

  onSendEmailUpdate(element) {
    let confirmSend = confirm("Are you sure?");

    if (confirmSend) {
      element.disabled = true;
      element.textContent = "Sending update...";

      this.alertService.sendEmailUpdate(this.siteId, this.alertId).subscribe(
        () => {
          console.log("Update sent");
          element.textContent = "Update Sent";
        },
        errorData => {
          console.log("error sending update");
          console.log(errorData);
          element.textContent = "Sending Failed";
        }
      );
    }
  }
  onSendForTranslation(element) {
    let confirmSend = confirm("Are you sure? This will also approve the alert");
    if (confirmSend) {
      let alertApproved: boolean;
      element.disabled = true;
      element.textContent = "Sending...";
      this.alertService.approveAlert(this.siteId, this.alertId).subscribe(
        () => {
          alertApproved = true;
          console.log("Alert Approved");
          element.textContent = "Approved";
          this.alertService.getAlert(this.siteId, this.alertId);
        },
        error => {
          console.log("error approving alert");
          element.disabled = false;
          console.log(error);
          alert(error.error);
          element.innerHTML =
            '<i class="fa fa-check-square-o"></i> Send For Translation';
        },
        () => {
          if (alertApproved === true) {
            this.updateAlertStatus({
              id: 4,
              description: "Pending Translation"
            });
            element.textContent = "Sent";
          }

          this.loading = false;
        }
      );
    }
  }

  onApproveAlert(element) {
    let confirmSend = confirm("Are you sure?");

    if (confirmSend) {
      element.disabled = true;
      element.textContent = "Approving...";

      this.alertService.approveAlert(this.siteId, this.alertId).subscribe(
        () => {
          console.log("Alert Approved");
          element.textContent = "Approved";
          this.alertService.getAlert(this.siteId, this.alertId);
        },
        error => {
          console.log("error approving alert");
          element.disabled = false;
          console.log(error);
          alert(error.error);
          element.innerHTML =
            '<i class="fa fa-check-square-o"></i> Approve Alert';
        }
      );
    }
  }
  alertIsPending() {
    if (this.alert != null) {
      if (this.alert.status != null) {
        if (this.alert.status.id != null) {
          return this.alertService.statusIsPending(
            this.alert.status.id.toString()
          );
        }
      }
    }
  }
  alertIsOngoing() {
    if (this.alert != null) {
      if (this.alert.status != null) {
        if (this.alert.status.id != null) {
          return this.alertService.statusIsOngoing(
            this.alert.status.id.toString()
          );
        }
      }
    }
  }

  alertStatus() {
    if (this.alert != null) {
      if (this.alert.status != null) {
        if (this.alert.status.id != null) {
          return this.alert.status.id;
        }
      }
    }
    return "";
  }
  alertSeverity() {
    if (this.alert != null) {
      if (this.alert.severity != null) {
        if (this.alert.severity.id != null) {
          return this.alert.severity.id;
        }
      }
    }
    return "";
  }

  getTagsInAlert() {
    let summaryAndRecommendation =
      JSON.stringify(this.alert.summary).toString() +
      JSON.stringify(this.alert.recommendation).toString();
    console.log("summaryAndRecommendation");
    this.tagDataSubscription = this.alertService
      .findMentionedTags(this.siteId, this.alertId)
      .subscribe(
        // tag request successful
        data => {
          console.log("got live tags");
          this.loadingTags = false;
          this.alertTags = [];
          for (let aTag of data) {
            // only add the tag if the name is longer than 3 characters
            if (aTag.toString().length >= 3) {
              this.alertTags.push(aTag);
            }
          }
          this.addRetrievedTags();
          this.getLiveChartData();
        },
        // error occured
        error => {
          console.log("error getting live tags");
          this.loadingTags = false;
        }
      );
  }

  onViewRecipients() {
    let recipientList = "";
    console.log("open recipients modal");
    this.alertService
      .getAlertRecipients(this.siteId, this.alertId)
      .subscribe(data => {
        const modalRef = this.modalService.open("");
      });
  }

  addRetrievedTags() {
    if (this.alertTags == null) {
      return;
    }
    // for (let signal of this.alertTags) {
    //   this.chart.addSeries({
    //     "name": signal,
    //     "tooltip": {
    //         valueDecimals: 1,
    //         // valueSuffix: signal.engUnits
    //     },
    //   })
    // }
  }

  getLiveChartData() {
    console.log("Requesting tag data");
    for (let aSignal of this.alertTags) {
      this.signalGetterService
        .getPiData(this.siteId, aSignal.toString())
        .subscribe(data => {
          this.loadedTags += 1;

          // iterate through each signal returned
          for (
            var iTag = 0, lenTag = data["piTagList"].length;
            iTag < lenTag;
            iTag++
          ) {
            let aTagData = data["piTagList"][iTag];

            // define the signal returned
            let newSignal = {};
            newSignal["engUnits"] = aTagData["engUnits"];
            newSignal["data"] = [];
            newSignal["tagName"] = aTagData["tagName"];
            let values = aTagData["piTagData"];

            for (
              var iPoint = 0, lenVals = values.length;
              iPoint < lenVals;
              iPoint++
            ) {
              let pointElement = values[iPoint];
              let dateString = pointElement["dateTime"];
              if (!dateString.includes("Z")) {
                dateString = dateString + "Z";
              }

              // newSignal['data'].push({
              //   "dateTime": dateString,
              //   "value": parseFloat(pointElement['tagValue'])
              // })

              newSignal["data"].push([
                Date.parse(dateString),
                parseFloat(pointElement["tagValue"])
              ]);
            }

            console.log("adding tag data for " + newSignal["tagName"]);
            console.log(newSignal["data"]);

            // this.chart['series'].find(x => x['name'].toString().toLower() == newSignal['tagName'].toString().toLower()).setData();

            this.chart.addSeries({
              name: newSignal["tagName"],
              tooltip: {
                valueDecimals: 1,
                valueSuffix: newSignal["engUnits"]
              },
              data: newSignal["data"]
            });
          }
        });
    }
  }

  moveToTop(fileId: string) {
    console.log("moving file " + fileId + " to top");
    let fileIndex = this.alertFiles.findIndex(x => x["hashId"] == fileId);
    let fileToTop = this.alertFiles.splice(fileIndex, 1);
    this.alertFiles.splice(0, 0, fileToTop[0]);
    let fileOrder = [];
    for (let aFile of this.alertImages()) {
      fileOrder.push(aFile.hashId);
    }
    this.alertService
      .saveFileOrdering(this.siteId, this.alertId, fileOrder)
      .subscribe(
        data => {
          console.log("saved file order");
        },
        error => {
          alert("Unable to save file order");
        }
      );
  }

  saveInstance(chartInstance) {
    console.log("save instance");
    this.chart = chartInstance;
    // need to only do this once
    // this.addRetrievedTags();
  }

  toggleFileSendWithUpdates(fileId: string) {
    let theFile = this.alertFiles.find(x => x["hashId"] == fileId);
    theFile["sendWithUpdates"] = !theFile["sendWithUpdates"];
    console.log(theFile);
    this.alertService
      .setFileToSendWithUpdates(
        this.siteId,
        this.alertId,
        fileId,
        theFile["sendWithUpdates"]
      )
      .subscribe(
        data => {
          console.log("saved SendWithUpdates setting");
        },
        error => {
          alert("Unable to toggle send-with-email-updates");
        }
      );
  }

  alertNote(noteName: string) {
    if (this.alert["notes"] == null) {
      return "(set)";
    }
    var maintNote = this.alert["notes"].find(x => x.name == noteName);
    if (maintNote == null) {
      return "(set)";
    }

    if (maintNote["description"].length == 0) {
      return "(set)";
    }

    return maintNote["description"];
  }

  editMaintNum() {
    var oldVar = this.alertNote("Maintenance Order Number");
    if (oldVar == "(set)") {
      oldVar = "";
    }
    var newVar = prompt("Edit Maintenance Number", oldVar);
    if (newVar == null) {
      return;
    }
    if (this.alert["notes"] == null) {
      this.alert["notes"] = [];
    }
    var oldNotiNum = this.alert["notes"].find(
      x => x.name == "Maintenance Order Number"
    );
    if (oldNotiNum != null) {
      oldNotiNum["description"] = newVar;
    } else {
      this.alert["notes"].push({
        name: "Maintenance Order Number",
        description: newVar
      });
    }
    this.alertService.updateAlertNotes(
      this.siteId,
      this.alertId,
      this.alert["notes"]
    );
  }
  editNotiNum() {
    var oldVar = this.alertNote("Notification Number");
    if (oldVar == "(set)") {
      oldVar = "";
    }
    var newVar = prompt("Edit Notification Number", oldVar);
    if (newVar == null) {
      return;
    }
    if (this.alert["notes"] == null) {
      this.alert["notes"] = [];
    }
    var oldNotiNum = this.alert["notes"].find(
      x => x.name == "Notification Number"
    );
    if (oldNotiNum != null) {
      oldNotiNum["description"] = newVar;
    } else {
      this.alert["notes"].push({
        name: "Notification Number",
        description: newVar
      });
    }
    this.alertService.updateAlertNotes(
      this.siteId,
      this.alertId,
      this.alert["notes"]
    );
  }

  deleteAlert() {
    if (confirm("Are you sure to delete this alert? This cannot be undone.")) {
      this.alertService.deleteAlert(this.siteId, this.alertId).subscribe(
        data => {
          alert("Alert deleted");
          this.router.navigate(["../"], { relativeTo: this.route });
        },
        error => {
          alert("Unable to delete alert");
        }
      );
    }
  }
  updateAlertStatus(aStatus) {
    if (
      confirm(
        "Are you sure you want to change the status to " +
          aStatus.description +
          "?"
      )
    ) {
      this.loading = true;
      this.alertService.updateStatus(this.siteId, this.alertId, aStatus.id);
    }
  }
  closeAlert() {
    if (confirm("Are you sure you want to close the alert?")) {
      this.loading = true;
      this.alertService.closeAlert(this.siteId, this.alertId);
    }
  }
}
