import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [CommonModule]
})
export class ConnStrModule {
  //
  // FOR ACM ACCOUNTS
  //
  //public acmAccountUrl = 'http://localhost:49374/api/';
  public acmAccountUrl = "https://acm-login-api.azurewebsites.net/api/";

  //
  // FOR THIS WEB APP
  //
  public smartHubBaseUrl = "https://app.prod.solutions/";
  public es_smartHubBaseUrl = "https://app-es.prod.solutions/";
  public de_smartHubBaseUrl = "https://app-de.prod.solutions/";

  //
  // FOR ALERT DATA
  //
  public pspBaseUrl = "https://acmpsp-api.azurewebsites.net/api/"; //Production API
  //public pspBaseUrl = "https://acmpsp-api-dev.azurewebsites.net/api/";          //Dev API
  //public pspBaseUrl = "http://localhost:61957/api/"; //Debug API

  //
  // FOR TIMESERIES DATA
  //
  //public universeDataUrl = 'https://universe-api-ext.azurewebsites.net/api/';
  public universeDataUrl = "https://acmuniversedata-api.azurewebsites.net/api/";

  //
  // FOR MSR DATA
  //
  //public universeDataUrl = 'https://universe-api-ext.azurewebsites.net/api/';
  public msrDataUrl = "https://acmuniverse-msr-api.azurewebsites.net/api/";

  //clientIds for enerlytics redirects
  public es_clientId: string = "TeG74HzlMMPHqGkzCJDRMQQOrDOi6PQY";
  public de_clientId: string = "4fv4W4QIcgBp99IBkLeHQIy99xcN0kAj";
  public default_clientId: string = "b0e14b0075374dbf997cb9ff9ab53200";
}
