import { Subscription } from "rxjs/Subscription";
import { ActivatedRoute, Params } from "@angular/router";
import { AlertsService } from "./../../_services/alerts.service";
import { Component, OnInit } from "@angular/core";
import { SitesService } from "../../_services/sites.service";
import { Site } from "../../_models/site.model";
//import {saveAs} from 'file-saver/Filesaver.js';
import * as fileSaver from "file-saver";

@Component({
  selector: "app-alert-archive",
  templateUrl: "./alert-archive.component.html",
  styleUrls: ["./alert-archive.component.css"]
})
export class AlertArchiveComponent implements OnInit {
  siteSubscription = new Subscription();

  errorMessage = "";

  searched = false;
  searching = false;
  loading = false;

  site: Site;
  siteId;

  searchStr = "";
  filterStr = "";

  displayMode = "short";
  alerts = [];

  constructor(
    private route: ActivatedRoute,
    private alertsService: AlertsService,
    private sitesService: SitesService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.siteId = params["siteId"];
      this.sitesService.getSite(this.siteId);
      if (this.alertsService.archivedAlertsSiteId == this.siteId) {
        this.searchStr = this.alertsService.archivedAlerts["search"];
        this.alerts = this.alertsService.archivedAlerts["alerts"];
        this.searched = true;
        this.searching = false;
        this.loading = false;
      }
    });

    this.siteSubscription = this.sitesService.siteChanged.subscribe(
      (site: Site) => {
        this.site = new Site(
          site.siteId,
          site.name,
          site.units,
          site.areas,
          site.items,
          site.latitude,
          site.longitude,
          site.zoom,
          site.bridgehaed
        );
      }
    );

    this.alertsService.archivedAlertsChanged.subscribe(
      data => {
        this.alerts = data["alerts"];
        this.loading = false;
        this.searched = true;
        this.searching = false;
      },
      error => {
        this.loading = false;
        this.searched = true;
        this.searching = false;
        this.errorMessage = "Unable to load results";
      }
    );
  }

  onSearch() {
    this.searching = true;
    this.alertsService.searchArchivedAlerts(this.siteId, this.searchStr);
  }

  onFilter() {}

  // Download list of alerts as CSV file
  exportAlertsToCSV(alerts) {
    var headers =
      "Date Created, Alert Name, Severity, Status, Alert Summary, Recommendation, Latest Feedback, Last Modified " +
      "\r\n";
    var csvAlerts = headers;
    //alert([new Date]);
    var filename = this.site.name + "_Exported Archived Alerts.csv";
    var alertStr = document.getElementById("selectedAlerts").innerText;

    var aa = 0;
    var bb = [];
    for (var ii = 0; ii < alerts.length; ii++) {
      if (alertStr.includes(alerts[ii].name)) {
        csvAlerts +=
          alerts[ii].utcCreated.slice(0, -5).replace(/T/, " ") +
          ',"' +
          alerts[ii].name +
          '",' +
          alerts[ii].severity.description +
          "," +
          alerts[ii].status.description +
          ',"' +
          alerts[ii].summary.replace(/(\r\n|\n|\r)/gm, " ") +
          '","' +
          alerts[ii].recommendation.replace(/(\r\n|\n|\r)/gm, " ") +
          '","' +
          alerts[ii].feedback.replace(/(\r\n|\n|\r)/gm, " ") +
          '",' +
          alerts[ii].utcModified.slice(0, -5).replace(/T/, " ") +
          "\r\n";
        aa++;
      }
      /* else {
          bb.push(ii);
        }  */
    }
    //alert([alerts.length, aa, ii]);
    //alert(bb);
    if (aa == 0) {
      alert("You have not selected any alerts to export");
    } else {
      var blob = new Blob(["\ufeff" + csvAlerts], {
        type: "application/vnd.ms-excel;charset=utf-8"
      });

      alert("A file will be saved as " + filename);

      saveAs(blob, filename);
    }
  }
}
