import { AuthenticationService } from "./../../_services/authentication.service";
import { InfoAlertService } from "./../../_services/info-alert.service";
import { SitesService } from "./../../_services/sites.service";
import { Subscription } from "rxjs/Subscription";
import { AlertsService } from "./../../_services/alerts.service";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { Site } from "./../../_models/site.model";
import { Alert } from "./../../_models/alert.model";
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { DataStorageService } from "../../_services/data-storage.service";
import { Observable, Subject, merge } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  map,
  filter
} from "rxjs/operators";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { element } from "protractor";
import { stringify } from "@angular/compiler/src/util";
//let sites = [];

@Component({
  selector: "app-alerts-home",
  templateUrl: "./alerts-home.component.html",
  styleUrls: ["./alerts-home.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class AlertsHomeComponent implements OnInit {
  loading = true;
  sites = [];
  siteName: any;
  favSite: any;

  @ViewChild("instance", { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  lat = 50;
  lng = 10;
  zoom = 3;
  styles = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5"
        }
      ]
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161"
        }
      ]
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5"
        }
      ]
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161"
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5"
        }
      ]
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertsService: AlertsService,
    private sitesService: SitesService,
    private dataStorageService: DataStorageService,
    private infoAlertService: InfoAlertService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.loading = true;

    this.favSite = this.alertsService.getFavouriteAlertsSite(
      this.authenticationService.loggedInUserHashId()
    );
    this.dataStorageService.getSitesData().subscribe(data => {
      this.sites = data.sort(function(a, b) {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      this.loading = false;
      console.log(this.sites);
    });
  }

  goToFavSite() {
    this.router.navigate([this.favSite.siteId], { relativeTo: this.route });
  }

  navigateTo(siteId: String) {
    let selectedSite: Site = this.sites.filter(
      site => (site["siteId"] = siteId)
    )[0];
    this.sitesService.siteChanged.next(selectedSite);
    console.log(selectedSite);

    // let siteId = $event.siteId;
    this.router.navigate([siteId], { relativeTo: this.route });
    console.log(JSON.stringify(siteId));
  }
  // resultFormatSiteId(value: any) {
  //   return value.siteId;
  // }
  // inputFormatSiteName(value: any) {
  //   return value.name;
  // }
  // search = (text$: Observable<string>) => {
  //   const debouncedText$ = text$.pipe(
  //     debounceTime(200),
  //     distinctUntilChanged()
  //   );
  //   const clicksWithClosedPopup$ = this.click$.pipe(
  //     filter(() => !this.instance.isPopupOpen())
  //   );
  //   const inputFocus$ = this.focus$;
  //   return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
  //     map(term =>
  //       term === ""
  //         ? sites.sort(function(a, b) {
  //             let nameA = a.name.toUpperCase(); // ignore upper and lowercase
  //             let nameB = b.name.toUpperCase(); // ignore upper and lowercase
  //             if (nameA < nameB) {
  //               return -1;
  //             }
  //             if (nameA > nameB) {
  //               return 1;
  //             }

  //             // names must be equal
  //             return 0;
  //           })
  //         : sites
  //             .sort(function(a, b) {
  //               let nameA = a.name.toUpperCase(); // ignore upper and lowercase
  //               let nameB = b.name.toUpperCase(); // ignore upper and lowercase
  //               if (nameA < nameB) {
  //                 return -1;
  //               }
  //               if (nameA > nameB) {
  //                 return 1;
  //               }

  //               // names must be equal
  //               return 0;
  //             })

  //             .filter(
  //               v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
  //             )
  //             .slice(0, 10)
  //     )
  //   );
  // };
}
