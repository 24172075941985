import { ConnStrModule } from "./conn-str.module";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Injectable, ɵLOCALE_DATA, OnInit } from "@angular/core";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable, throwError, BehaviorSubject, Subject } from "rxjs";
import "rxjs/add/operator/map";
import { take } from "rxjs/operators";
import { PspUser } from "../_models/user.model";
import { Router } from "@angular/router";
import { ThrowStmt } from "@angular/compiler";
import { InfoAlertService } from "./../_services/info-alert.service";
const jwtHelper = new JwtHelperService();

@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private connStr: ConnStrModule,
    private router: Router
  ) {}

  currentUserSubject = new Subject<PspUser>();
  locale = Object.keys(ɵLOCALE_DATA)
    .slice(0, 1)
    .toString();

  login(username: string, password: string) {
    const authbody = {
      username: username,
      password: password
    };
    this.loginUniverseData(username, password);
    console.log(`locale:${this.locale}`);
    return this.http
      .post<any>(this.connStr.pspBaseUrl + "token", authbody)
      .map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.processHubTokens(user);
          // console.log(localStorage.getItem('currentUser'));
          this.refreshUniverseToken();
          //localStorage.setItem('currentUser', JSON.stringify(user));
        }
      });
  }

  refreshHubToken() {
    console.log("refreshing hub token");

    let reqUrl = this.connStr.pspBaseUrl + "/ExternalAuth/SmartHub";
    var token = this.getHubRefreshToken();
    if (token) {
      return this.http
        .get(
          `${reqUrl}?code=${this.getHubRefreshToken()}&grant_type=refresh_token`
        )
        .map(response => {
          return response;
        });
    } else {
      Observable.throw("No refresh token");
    }
  }

  msrLogin(authToken: string) {
    return this.http
      .get(this.connStr.msrDataUrl + "ExternalAuth/SmartHub?token=" + authToken)
      .map((response: HttpResponse<any>) => {
        if (response.ok) {
          let user = response.body.json();
          if (user && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.processMsoTokens(user);
          }
        }
      });
  }
  acmLogin(authToken: string) {
    console.log("acmLogin");
    return this.http
      .get(this.connStr.pspBaseUrl + "ExternalAuth/SmartHub?token=" + authToken)
      .map((response: HttpResponse<any>) => {
        if (response.ok) {
          let user = response.body.json();
          console.log(user);
          if (user && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.processHubTokens(user);
            this.refreshUniverseToken();
          }
        }
      });
  }

  enerlyticsLogin(authToken: string, locale: string = null) {
    console.log(`enerlytics Login: ${locale}`);
    return this.http
      .get(
        `${this.connStr.pspBaseUrl}ExternalAuth/enerlytics?locale=${locale}&code=${authToken}`
      )
      .map((response: HttpResponse<any>) => {
        if (response) {
          console.log(response);

          // store user details and jwt token in local storage to keep user logged in between page refreshes
          console.log("Storing enerlytics token :" + response["access_token"]);
          localStorage.setItem("currentUser", response["access_token"]);
          this.refreshUniverseToken();
        }
      });
  }

  refreshUniverseToken() {
    let token = this.getToken();
    if (token) {
      let headers = new HttpHeaders();
      headers.append("Authorization", "Bearer " + token);
      let options = {
        headers: headers
      };

      return this.http
        .get(this.connStr.pspBaseUrl + "ApiLogins/ftl/Token", options)
        .map((response: HttpResponse<any>) => {
          return response;
        });
    } else {
      console.log("No universe token found");
      return throwError("No universe refresh token");
    }
  }

  getMsrToken() {
    let headers = new HttpHeaders();
    headers.append("Authorization", "Bearer " + this.getToken());
    let options = {
      headers: headers
    };

    console.log("posting for msr token");
    return this.http
      .get(this.connStr.pspBaseUrl + "ApiLogins/msr/Token", options)
      .map((response: HttpResponse<any>) => {
        console.log("msr response");
        console.log(response);
        if (response.ok) {
          let user = response.body.json();
          if (user && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.processMsoTokens(user);
            console.log("msr token received");
          }
        }
      });
  }

  loginUniverseData(username: string, password: string) {
    // get the username and password into the form the API accepts
    const authbody = new HttpParams()
      .set("username", username)
      .set("password", password);

    console.log("posting to ftl: " + authbody);
    return this.http
      .post(this.connStr.universeDataUrl + "token", authbody)
      .subscribe(response => {
        console.log("Universe response: " + response);

        console.log("FTL Login Success!");
        //console.log(response.json())
        let user = response;
        console.log("ftl token: " + user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.processUniverseTokens(user);
      });
  }
  isBridgehead(siteId: string = "") {
    let user_isBridgeHead = false;
    let userAccessJson = this.getUserAccess();
    if (userAccessJson == null) {
      return false;
    }
    let rolesList = JSON.parse(userAccessJson);

    if (rolesList["Roles"] != null) {
      rolesList["Roles"].forEach(globalRole => {
        if (globalRole.toLowerCase() == "bridgehead") {
          user_isBridgeHead = true;
        }
      });
    }
    if (rolesList["Companies"] != null) {
      rolesList["Companies"].forEach(company => {
        if (company["Roles"] != null) {
          company["Roles"].forEach(companyRole => {
            if (companyRole.toLowerCase() == "bridgehead") {
              user_isBridgeHead = true;
            }
          });
        }
      });
    }
    return user_isBridgeHead;
  }

  isAcm(siteId: string) {
    let acmFound = false;
    let userAccessJson = this.getUserAccess();
    if (userAccessJson == null) {
      return false;
    }

    let rolesList = JSON.parse(userAccessJson);

    if (rolesList["Roles"] != null) {
      rolesList["Roles"].forEach(globalRole => {
        if (globalRole.toLowerCase() == "analyst") {
          acmFound = true;
          return acmFound;
        }
      });
    }

    if (acmFound) {
      return true;
    }
    if (rolesList["Companies"] != null) {
      rolesList["Companies"].forEach(company => {
        if (company["Roles"] != null) {
          company["Roles"].forEach(companyRole => {
            if (companyRole.toLowerCase() == "analyst") {
              acmFound = true;
              return acmFound;
            }
          });
        }
      });
    }
    return acmFound;
  }

  hasRole(roleStr: string) {
    if (!localStorage.getItem("currentUser")) {
      return false;
    }
    var userToken = jwtHelper.decodeToken(localStorage.getItem("currentUser"));
    if (!userToken["roles"]) {
      return false;
    }
    let userRoles: string[] = userToken["roles"];
    if (userRoles.indexOf(roleStr.toLowerCase()) >= 0) {
      return true;
    }
    return false;
  }

  isFromEnerlytics() {
    return this.hasRole("enerlytics");
  }

  isMsr() {
    let msrFound = false;
    let msrAccessJson = localStorage.getItem("msrToken");
    if (msrAccessJson == null) {
      return false;
    }
    return true;
  }

  isAuthenticated() {
    if (localStorage.getItem("currentUser")) {
      if (jwtHelper.isTokenExpired(localStorage.getItem("currentUser"))) {
        console.log("Token expired");
      } else {
        return true; // logged in so return true
      }
    }
    return false;
  }

  getToken() {
    return localStorage.getItem("currentUser");
  }
  getHubRefreshToken() {
    return localStorage.getItem("hub_refresh_token");
  }
  getUniverseDataToken() {
    return localStorage.getItem("ftlToken");
  }

  RememberMeLogin() {
    var toRemember: Boolean = JSON.parse(localStorage.getItem("rememberMe"));
    if (toRemember) {
      return true;
    }
    return false;
  }
  changeRememberMe(remember: Boolean) {
    localStorage.setItem("rememberMe", remember.toString());
  }

  processHubTokens(tokensJson) {
    localStorage.setItem("currentUser", tokensJson.access_token);
    if (this.RememberMeLogin()) {
      localStorage.setItem("hub_refresh_token", tokensJson.refresh_token);
    }
  }
  processUniverseTokens(tokensJson) {
    console.log("storing universe token", tokensJson);
    localStorage.setItem("ftlToken", tokensJson.access_token);
  }
  processMsoTokens(tokensJson) {
    localStorage.setItem("msrToken", tokensJson.access_token);
  }
  processEnerlyticsTokens(tokensJson) {
    console.log("storing enerlytics token", tokensJson);
    localStorage.setItem("enerlyticsToken", tokensJson.access_token);
  }
  logout(resetPassword: boolean = false) {
    // remove user tokens from local storage to log user out
    localStorage.removeItem("hub_refresh_token");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("_currentUser");
    localStorage.removeItem("ftlToken");
    localStorage.removeItem("msrToken");
    this.changeRememberMe(false);
    if (!resetPassword) () => this.router.navigate(["./login"]);
  }

  getUserAccess() {
    if (localStorage.getItem("currentUser")) {
      if (!jwtHelper.isTokenExpired(localStorage.getItem("currentUser"))) {
        var userToken = jwtHelper.decodeToken(
          localStorage.getItem("currentUser")
        );
        var userAccess = userToken["globalRoles"];
        return userAccess;
      }
    }
  }

  requestResetLink(email: string) {
    console.log("requestResetLink() for " + email);
    return this.http.post(
      `${this.connStr.pspBaseUrl}resetpassword/EmailResetLink?email=${email}`,
      "",
      { responseType: "text" }
    );
  }

  requestAccount(email: string, name: string) {
    console.log("requestaccount for " + email + " " + name);

    let details = {
      email: email,
      name: name
    };

    return this.http
      .post(this.connStr.pspBaseUrl + "NewUser/RequestAccount", details)
      .map(response => {
        return response;
      });
  }

  checkResetLink(resetId: string) {
    return this.http
      .get(`${this.connStr.pspBaseUrl}resetpassword?resetId=${resetId}`, {
        observe: "response"
      })
      .map((response: HttpResponse<any>) => {
        return response;
      });
  }

  resetPassword(resetId: string, newPassword: string) {
    console.log("resetPassword()");
    // set the headers to tell the api the username and password are from a form
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let passwordJson = {
      password: newPassword
    };

    return this.http
      .post(
        `${this.connStr.pspBaseUrl}resetpassword/?resetId=${resetId}`,
        passwordJson,
        { headers, responseType: "text" }
      )
      .map(response => {
        return response;
      });
  }

  loggedInUserHashId() {
    let hashStr: string;

    if (localStorage.getItem("currentUser")) {
      if (!jwtHelper.isTokenExpired(localStorage.getItem("currentUser"))) {
        var userToken = jwtHelper.decodeToken(
          localStorage.getItem("currentUser")
        );
        console.log(userToken);
        hashStr = userToken["sub"];
      }
    }

    return hashStr;
  }
  getUser() {
    return this.http.get(`${this.connStr.pspBaseUrl}user`).map(User => {
      let currentUser = new PspUser(
        User["userId"],
        User["authId"],
        User["name"],
        User["email"]
      );
      return currentUser;
    });
  }
}
