import { Injectable } from "@angular/core";

export class Site {
  constructor(
    public siteId: string,
    public name: string,
    public units = [],
    public areas = [],
    public items = [],
    public latitude = 0,
    public longitude = 0,
    public zoom = 0,
    public bridgehaed: boolean
  ) {}
}
