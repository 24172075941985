import { FormGroup, FormControl } from "@angular/forms";
import { DataStorageService } from "./../../_services/data-storage.service";
import { Subscription } from "rxjs/Subscription";
import { SitesService } from "./../../_services/sites.service";
import { ActivatedRoute, Params } from "@angular/router";
import { AlertsService } from "./../../_services/alerts.service";
import { Site } from "./../../_models/site.model";
import { Alert } from "./../../_models/alert.model";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthenticationService } from "../../_services/authentication.service";
import { setTimeout } from "timers";

import * as SunCalc from "suncalc";
//import * as fileSaver from 'file-saver';
import { saveAs } from "file-saver";
import { StringDecoder } from "string_decoder";
import { ChartSeries } from "../../_models/series.model";
import { BlobFile } from "../../_models/blob-file.model";
import { HttpResponse } from "@angular/common/http";

//declare var $:JQueryStatic;

@Component({
  selector: "app-alert-list",
  templateUrl: "./alert-list.component.html",
  styleUrls: ["./alert-list.component.css"]
})
export class AlertListComponent implements OnInit {
  siteSubscription: Subscription = new Subscription();
  alertsSubscription: Subscription = new Subscription();
  alertSeveritySubscription: Subscription = new Subscription();
  alertStatusSummarySubscription: Subscription = new Subscription();
  alertStatusTypesSubscription: Subscription = new Subscription();
  alertSeverityTypesSubscription: Subscription = new Subscription();

  sparkLines = [];

  chartSeverity;
  optionsSeverity: Object;
  severitySummary;

  alertmode = "allalerts";
  filter = false;

  chartStatus;
  optionsStatus: Object;
  statusSummary;

  totalAlerts = 0;
  feedbackReqStr = "Loading...";
  feedbackGivenStr = "Loading...";
  acknowledgedStr = "Loading...";
  siteName: string;
  siteId: string;
  alerts: Alert[] = new Array<Alert>();
  site: Site;
  filterStr = "";
  loading = true;

  isAcmUser = false;

  displayMode = "short";

  dropdownSettings = {
    text: "Filter Alert Status or Severity",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    enableSearchFilter: true,
    badgeShowLimit: 1
  };

  // cityId = 2643743;

  showMoon = false;

  alertForm: FormGroup;

  // Data as it comes back from the API
  // These are converted into the lists used by the api
  analysisTypes: { [id: number]: string }[] = [];
  statusTypes: { [id: number]: string }[] = [];
  severity: { [id: number]: string }[] = [];

  // Arrays to be used by the drop-downs
  dropdownListUnits = [];
  selectedUnits = [];
  dropdownListAreas = [];
  selectedAreas = [];
  dropdownListItems = [];
  selectedItems = [];
  dropdownListAnalysis = [];
  selectedAnalysis = [];
  dropdownListStatus = [];
  selectedStatus = [];
  dropdownListSeverity = [];
  //selectedSeverity = [];
  //selectedAlerts = [];

  onlyOpenAlerts = true;

  temperature = "loading...";
  wind = "loading...";

  lat: number = 0;
  lng: number = 0;
  zoom: number = 1;
  styles = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5"
        }
      ]
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161"
        }
      ]
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5"
        }
      ]
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161"
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5"
        }
      ]
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private alertsService: AlertsService,
    private sitesService: SitesService,
    private dataStorageService: DataStorageService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnDestroy() {
    this.siteSubscription.unsubscribe();
    this.alertsSubscription.unsubscribe();
    this.alertSeveritySubscription.unsubscribe();
    this.alertStatusSummarySubscription.unsubscribe();
    this.alertStatusTypesSubscription.unsubscribe();
    this.alertSeverityTypesSubscription.unsubscribe();
  }

  ngOnInit() {
    this.optionsSeverity = {
      chart: {
        type: "pie",
        marginTop: 0,
        reflow: true
      },
      title: {
        text: /* () => {
          if (this.authenticationService.locale == "de") {
            return "Warnen<br>Schwere";
          } else if (this.authenticationService.locale == "de") {
          }
          return "Alert<br>Severities";
        }, */
        this.authenticationService.locale == "en"
          ? "Alert<br>Severities"
          : "Warnen<br>Schwere",
        align: "center",
        verticalAlign: "middle",
        y: -5,
        style: {
          fontSize: "1.5rem",
          color: "#495057"
        }
      },
      subtitle: {
        text: ""
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          events: {
            click: (event: any) => {
              console.log(
                "SeverityString=> " + event.currentTarget.textContent
              );
              var str = event.currentTarget.textContent;

              var selectedSev = "";
              if (str.includes("Significant")) {
                selectedSev = "Significant";
              } else if (str.includes("fault")) {
                selectedSev = "fault";
              } else if (str.includes("Deviations")) {
                selectedSev = "Deviations";
              } else if (str.includes("Communication")) {
                selectedSev = "Communication";
              } else if (str.includes("unavailable")) {
                selectedSev = "unavailable";
              } else if (str.includes("Satisfactory")) {
                selectedSev = "Satisfactory";
              }

              // to filter alerts on selected severity
              this.addFilterOption(selectedSev);
            }
          },

          dataLabels: {
            enabled: false
          }
          // showInLegend: true
        }
      },

      // this replaces the series name with the number of points
      legend: {
        labelFormatter: function() {
          return this.y;
        }
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 300,
              maxHeight: 300
            },
            chartOptions: {
              series: [
                {
                  id: "versions",
                  dataLabels: {
                    enabled: false
                  }
                }
              ]
            }
          }
        ]
      },
      credits: {
        enabled: false
      },
      series: [
        {
          type: "pie",
          name: "Alerts",
          innerSize: "70%",
          data: []
        }
      ]
    };

    this.route.params.subscribe((params: Params) => {
      this.siteId = params["siteId"];
    });
    this.isAcmUser = this.authenticationService.isAcm(this.siteId);
    this.getSiteLoadTags();
    //this.sitesService.getSite('siteId');
    this.siteSubscription = this.dataStorageService
      .getSiteData(this.siteId)
      .subscribe(
        (site: Site) => {
          console.log("site data");
          console.log(site);
          this.site = site;
          this.siteName = site.name;

          this.alertsService.setFavouriteAlertsSite(
            this.site.siteId,
            this.site.name,
            this.authenticationService.loggedInUserHashId()
          );

          if (site["longitude"] && site["latitude"] && site["zoom"]) {
            this.lng = parseFloat(site["longitude"].toString());
            this.lat = parseFloat(site["latitude"].toString());
            this.zoom = parseInt(site["zoom"].toString());

            this.getWeatherData();
            console.log("weather data end");
          }
        },
        error => {
          this.optionsStatus["title"]["text"] = "unable to load status data";
        },
        () => {
          this.alertSeveritySubscription = this.alertsService
            .getAlertsSeveritySummary(this.siteId)
            .subscribe(
              data => {
                this.makeSeverityPie(data);
                this.initStatusTypes();
              },
              error => {
                this.optionsSeverity["title"]["text"] =
                  "unable to load severity data";
              }
            );
        }
      );
    this.alertStatusSummarySubscription = this.alertsService
      .getAlertsStatusSummary(this.siteId)
      .subscribe(data => {
        this.makeStatusPie(data);
        console.log("status pi f");
      });

    this.alertsSubscription = this.alertsService.alertsChanged.subscribe(
      (alerts: Alert[]) => {
        this.alerts = alerts;
        this.loading = false;
        this.buildAnalysisList();
      }
    );

    this.alertStatusTypesSubscription = this.alertsService.statusTypesChanged.subscribe(
      data => {
        this.statusTypes = data;
        this.initStatusTypes();
      }
    );

    this.sitesService.getSite(this.siteId);
    this.alertsService.getAlerts(this.siteId, this.onlyOpenAlerts, this.filter);
    this.alertsService.getStatusTypes();
    this.alertsService.getSeverities();

    this.initForm();
  }
  isBridgehead() {
    return this.authenticationService.isBridgehead("");
  }
  GetFilterArgs() {
    //no longer filtering bridgehead alerts as Lee wants them to see all alerts.
    if (this.isBridgehead()) {
      //return {description: 'Pending Translation'}
      return "";
    }
    return "";
  }

  filterArgs = this.GetFilterArgs();

  getSiteLoadTags() {
    this.sitesService.getSiteLoadTags(this.siteId).subscribe(
      data => {
        this.sparkLines = data;
      },
      error => {}
    );
  }

  makeSeverityPie(data: {}) {
    console.log("got severity summary");
    console.log(data);
    let severityData = [];

    if (data == null || data["alertsBySeverity"] == null) {
      return;
    }

    for (let sevs of data["alertsBySeverity"]) {
      // let isVisible = (sevs['severityId'] != 40);

      let pointColour = this.getSeverityColour(sevs["severityId"]);
      severityData.push({
        id: sevs["severityId"],
        name: sevs["severityDescription"],
        y: sevs["totalAlertCount"],
        color: pointColour
        // visible: isVisible
      });
    }
    this.chartSeverity["series"][0].setData(severityData);
    this.totalAlerts = data["totalAlertCount"];

    // this.chartSeverity.setTitle({
    //   text: this.totalAlerts + " Alerts by<br>Severity"
    // });
  }

  makeStatusPie(data: {}) {
    console.log("got status summary");
    console.log(data);
    let statusData = [];
    let otherAlertCount = 0;
    if (data == null || data["alertsByStatus"] == null) {
      this.feedbackReqStr = "No open alerts";
      this.feedbackGivenStr = "No open alerts";
      this.acknowledgedStr = "No open alerts";
      return;
    }
    for (let stats of data["alertsByStatus"]) {
      let statusCategory = this.statusType(stats["statusId"]);

      if (statusCategory == "actionRequired") {
        this.feedbackReqStr = stats["totalAlertCount"];
      }
      if (statusCategory == "actionMade") {
        this.feedbackGivenStr = stats["totalAlertCount"];
      }
      if (statusCategory == "other") {
        otherAlertCount += stats["totalAlertCount"];
        this.acknowledgedStr = otherAlertCount.toString();
      }

      let pointColour = this.getStatusColour(stats["statusId"]);
      statusData.push({
        name: stats["statusDescription"],
        y: stats["totalAlertCount"],
        color: pointColour
      });
    }
    // this.chartStatus['series'][0].setData(statusData);
    // this.chartStatus.setTitle({
    //   text: data['totalAlertCount'] + " Alerts by<br>Status"
    // });
  }

  getSeverityColour(severityId: number) {
    switch (severityId) {
      case 10: {
        return "#ff0000";
      }
      case 20: {
        return "#ffa500";
      }
      case 30: {
        return "#ffff00";
      }
      case 40: {
        return "#dbdbdb";
      }
      case 50: {
        return "#52f2fa";
      }
      case 60: {
        return "#92d050";
      }
      default: {
        return "";
      }
    }
  }

  getStatusColour(statusId: number) {
    switch (statusId) {
      // requires feedback
      case 3: {
        return "#007bff";
      }
      // feedback received
      case 11: {
        return "#28a745";
      }
      default: {
        return "#868e96";
      }
    }
  }

  alertModeChanged() {
    if (this.alertmode == "allalerts") {
      this.filter = false;
    } else {
      this.filter = true;
    }
    console.log("alert mode = " + this.alertmode);
    console.log("getReport() filter = " + this.filter);
    this.loading = true;
    this.alertsService.getAlerts(this.siteId, this.onlyOpenAlerts, this.filter);
  }

  addFilterOption(filterOption: string) {
    if (filterOption == "requiresFeedback") {
      if (
        this.selectedStatus.find(
          aStatus => aStatus["itemName"] == "Requires Feedback"
        ) == null
      ) {
        this.selectedStatus.length = 0;
        this.selectedStatus.push({
          id: 3,
          itemName: "Requires Feedback"
        });
      }
    }
    if (filterOption == "feedbackReceived") {
      if (
        this.selectedStatus.find(
          aStatus => aStatus["itemName"] == "Feedback Received"
        ) == null
      ) {
        this.selectedStatus.length = 0;
        this.selectedStatus.push({
          id: 11,
          itemName: "Feedback Received"
        });
      }
    }
    // ['Ongoing', 'Maintenance Planned', 'Pending Return to Service', 'Maintenance Considered']
    if (filterOption == "acknowledgedAlerts") {
      if (
        this.selectedStatus.find(
          aStatus => aStatus["itemName"] == "Acknowledged"
        ) == null
      ) {
        this.selectedStatus.length = 0;
        this.selectedStatus.push(
          {
            id: 2,
            itemName: "Ongoing"
          },
          {
            id: 7,
            itemName: "Maintenance Planned"
          },
          {
            id: 8,
            itemName: "Pending Return to Service"
          },
          {
            id: 13,
            itemName: "Maintenance Considered"
          }
        );
      }
    }

    //Severity Filters
    if (filterOption == "Significant") {
      if (
        this.selectedStatus.find(
          aStatus => aStatus["itemName"] == "Significant"
        ) == null
      ) {
        this.selectedStatus.length = 0;
        this.selectedStatus.push({
          id: 10,
          itemName:
            "Significant indications of damage requiring immediate action"
        });
      }
    }

    if (filterOption == "fault") {
      if (
        this.selectedStatus.find(aStatus => aStatus["itemName"] == "fault") ==
        null
      ) {
        this.selectedStatus.length = 0;
        this.selectedStatus.push({
          id: 20,
          itemName:
            "Plant operating with suspected or known fault with signs of slow degradation"
        });
      }
    }

    if (filterOption == "Deviations") {
      if (
        this.selectedStatus.find(
          aStatus => aStatus["itemName"] == "Deviations"
        ) == null
      ) {
        this.selectedStatus.length = 0;
        this.selectedStatus.push({
          id: 30,
          itemName:
            "Deviations have been noted. Close monitoring being applied."
        });
      }
    }

    if (filterOption == "Communication") {
      if (
        this.selectedStatus.find(
          aStatus => aStatus["itemName"] == "Communication"
        ) == null
      ) {
        this.selectedStatus.length = 0;
        this.selectedStatus.push({
          id: 40,
          itemName: "Communication / instrumentation - no data"
        });
      }
    }

    if (filterOption == "unavailable") {
      if (
        this.selectedStatus.find(
          aStatus => aStatus["itemName"] == "unavailable"
        ) == null
      ) {
        this.selectedStatus.length = 0;
        this.selectedStatus.push({
          id: 50,
          itemName: "Plant unavailable"
        });
      }
    }

    if (filterOption == "Satisfactory") {
      if (
        this.selectedStatus.find(
          aStatus => aStatus["itemName"] == "Satisfactory"
        ) == null
      ) {
        this.selectedStatus.length = 0;
        this.selectedStatus.push({
          id: 60,
          itemName: "Satisfactory"
        });
      }
    }

    // to trigger the alert filter pipe
    this.onStatusSelectionChange();
  }

  statusType(statusId: number) {
    switch (statusId) {
      // requires feedback
      case 3: {
        return "actionRequired";
      }
      // feedback received
      case 11: {
        return "actionMade";
      }
      default: {
        return "other";
      }
    }
  }

  saveInstanceSeverity(chartInstance) {
    console.log("save instance");
    // chartInstance.containerWidth = "100%"
    this.chartSeverity = chartInstance;
    // this.chart.setSize("100%","50%")
    console.log(this.chartSeverity);
  }
  saveInstanceStatus(chartInstance) {
    console.log("save instance");
    // chartInstance.containerWidth = "100%"
    this.chartStatus = chartInstance;
    // this.chart.setSize("100%","50%")
    console.log(this.chartStatus);
  }

  getWeatherData() {
    console.log("Getting weather data");
    if (this.lng && this.lat) {
      console.log("lng " + this.lng + " lat " + this.lat);
    } else {
      console.log("No location. Skipping weather.");
      return;
    }
    this.sitesService.getWeatherData(this.lng, this.lat).subscribe(
      data => {
        this.temperature =
          (parseFloat(data["main"]["temp"]) - 273).toFixed(1) + " ℃";
        this.wind = parseFloat(data["wind"]["speed"]).toFixed(1) + " m/s";
      },
      error => {
        this.temperature = "no data";
        this.wind = "no data";
      }
    );
  }

  getForecastData() {
    this.sitesService.getForecastData(this.lng, this.lat).subscribe(
      (data: HttpResponse<any>) => {
        console.log("forecast data");
        data = data.body.json();
        console.log(data);
        // this.temperature = (parseFloat((data['main']['temp'])) - 273).toFixed(1) + ' ℃';
        // this.wind = parseFloat((data['wind']['speed'])).toFixed(1) + ' m/s' ;
      },
      error => {
        // this.temperature = "no data";
        // this.wind = "no data";
      }
    );
  }

  alertPlaceholder() {
    if (this.loading) {
      return "Loading alerts...";
    }
    return "Filter " + this.alerts.length + " Alerts";
  }

  toggleGetClosedAlerts() {
    this.onlyOpenAlerts = !this.onlyOpenAlerts;
    this.loading = true;
    this.alertsService.getAlerts(this.siteId, this.onlyOpenAlerts, this.filter);
  }

  // Download list of alerts as CSV file
  exportAlertsToCSV(alerts) {
    var headers =
      "Date Created, Alert Name, Severity, Status, Alert Summary, Recommendation, Latest Feedback, Last Modified " +
      "\r\n";
    var csvAlerts = headers;
    //alert([new Date]);
    var filename = this.site.name + "_Exported Alerts.csv";
    var alertStr = document.getElementById("selectedAlerts").innerText;

    var aa = 0;
    for (var ii = 0; ii < alerts.length; ii++) {
      if (alertStr.includes(alerts[ii].name)) {
        csvAlerts +=
          alerts[ii].utcCreated.slice(0, -5).replace(/T/, " ") +
          ',"' +
          alerts[ii].name +
          '",' +
          alerts[ii].severity.description +
          "," +
          alerts[ii].status.description +
          ',"' +
          alerts[ii].summary.replace(/(\r\n|\n|\r)/gm, " ") +
          '","' +
          alerts[ii].recommendation.replace(/(\r\n|\n|\r)/gm, " ") +
          '","' +
          alerts[ii].feedback.replace(/(\r\n|\n|\r)/gm, " ") +
          '",' +
          alerts[ii].utcModified.slice(0, -5).replace(/T/, " ") +
          "\r\n";
        aa++;
      }
    }

    if (aa == 0) {
      alert("You have not selected any alerts to export");
    } else {
      var blob = new Blob(["\ufeff" + csvAlerts], {
        type: "application/vnd.ms-excel;charset=utf-8"
      });

      alert("A file will be saved as " + filename);

      saveAs(blob, filename);
    }
  }

  private initForm() {
    this.selectedItems = [];
    this.selectedAreas = [];
    this.selectedUnits = [];
    this.selectedAnalysis = [];
    this.selectedStatus = [];
    //this.selectedSeverity = [];
    // this.selectedAlerts = [];

    this.alertForm = new FormGroup({
      status: new FormControl(this.selectedStatus),
      //'severity': new FormControl(this.selectedSeverity),
      units: new FormControl(this.selectedUnits),
      areas: new FormControl(this.selectedAreas),
      items: new FormControl(this.selectedItems),
      analysis: new FormControl(this.selectedAnalysis)
      //'selectedAlerts': new FormControl(this.selectedAlerts)
    });
    // console.log(this.alertForm)
  }

  private initStatusTypes() {
    this.dropdownListStatus = [];
    console.log(this.statusTypes);
    // iterate throught the list of status types
    for (let status in this.statusTypes) {
      let value = this.statusTypes[status];

      // iterate through the properties of the status type to find the id and name
      for (var prop in value) {
        if (value[prop].toLowerCase().indexOf("closed") != -1) {
          continue;
        }
        if (value[prop].toLowerCase().indexOf("approval") != -1) {
          continue;
        }
        if (value[prop].toLowerCase().indexOf("creation") != -1) {
          continue;
        }

        this.dropdownListStatus.push({
          id: parseInt(prop),
          itemName: value[prop]
        });
      }
    }

    // iterate through the properties of the severity type to find the id and name
    for (let alertSeverity of this.optionsSeverity["series"][0].data) {
      if (
        this.dropdownListStatus.find(
          sType => sType["id"] == alertSeverity["id"]
        ) == null
      ) {
        this.dropdownListStatus.push({
          id: parseInt(alertSeverity["id"]),
          itemName: alertSeverity["name"]
        });
      }
    }

    console.log("status types set");
    console.log(this.dropdownListStatus);
  }

  buildAnalysisList() {
    console.log("buildAnalysisList()");

    this.dropdownListAnalysis = [];
    this.analysisTypes = [];

    // console.log(this.alerts);
    if (this.alerts == null) {
      return;
    }
    for (let alert of this.alerts) {
      for (let alertAnalysis of alert["analysisTypes"]) {
        if (
          this.dropdownListAnalysis.find(
            aType => aType["id"] == alertAnalysis["id"]
          ) == null
        ) {
          this.dropdownListAnalysis.push({
            id: parseInt(alertAnalysis["id"]),
            itemName: alertAnalysis["description"]
          });
        }
      }
    }
  }

  // This triggers the alert filter pipe to fire
  onStatusSelectionChange() {
    this.selectedStatus = this.selectedStatus.slice();
  }
  severityText(loacale: string): string {
    return;
  }
  setLongLatZoom() {
    var newLong = 0;
    var newLat = 0;
    var newZoom = 0;

    newLat = parseFloat(prompt("Latitude", this.lat.toString()));

    if (newLat == null) {
      return;
    }

    newLong = parseFloat(prompt("Longitude", this.lng.toString()));

    if (newLong == null) {
      return;
    }
    newZoom = parseInt(
      prompt(
        "Zoom\n0 = whole world\n21 = individual buildings)",
        this.zoom.toString()
      )
    );

    if (newZoom != null) {
      this.sitesService
        .updateMapDetails(this.siteId, newLong, newLat, newZoom)
        .subscribe(data => {
          this.zoom = data["zoom"];
          this.lng = data["longitude"];
          this.lat = data["latitude"];
        });
    }
  }
}
